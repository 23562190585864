
.spinner {
    display: flex;
    width: 54px;
    height: 54px;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    .spinner {
        position: relative;
        display: flex;
        height: 36px;
        width: 36px;
        animation: SpinnerAnimationShow .25s normal ease, SpinnerAnimationRotation .7s linear infinite;
        transition-property: opacity, transform;
        transition-timing-function: ease;
        transform-origin: 50% 50%;

        .spinnerEllipse {
            fill: transparent;
            stroke-width: 1.5;
            stroke-linecap: round;
            stroke-dasharray: 60;
            stroke-dashoffset: 20;
            stroke: #30414b;
        }
    }

}

@keyframes SpinnerAnimationShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes SpinnerAnimationRotation {
    100% {
        transform: rotate(360deg);
    }
}
