body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

okta-heading {
  bottom: 0;
  color: #00297a;
  font-family: proxima-nova, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.2;
  top: auto;
}

content-container {
  height: 220px;
  left: 0;
  position: fixed;
  right: 0;
  top: 30%;
}

okta-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 200px;
  width: 200px;
}