body {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  font-family: 'Merriweather Sans', sans-serif;
}

* {
  padding: 0;
  margin: 0;
}

.centre {
  display: flex;
  justify-content: center;
}
.image {
  width: 300px;
  display: flex;
  justify-content: center;
}

.info-text{
  padding-left: 27px;
  padding-right: 9px;
  position: absolute;
  bottom: 0px;
  left:0px;
  padding-bottom: 50px;

}